import React, { useEffect, useState } from 'react'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { listDivisions, listParticipants } from '../graphql/queries'
import { simpleParticipant } from '../customgraphql/mutations'
import { Heading, Tabs, TabItem, Alert, Authenticator } from '@aws-amplify/ui-react'
import {
  Table,
  TableCell,
  TableRow
} from '@aws-amplify/ui-react';
import { TextField, Button, RadioGroupField, Radio, Flex } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css'
import awsExports from "../aws-exports";

Amplify.configure(awsExports);

const initialState = {  firstName: '', lastName: '', division: 'Open', streetAddr: '',
                        city: '', state: '', zip: '', phoneNumber: '', email: '',
                        ticketNumber: '' , error: false }

const Participants = () => {

    const [formState, setFormState] = useState(initialState)
    const [openDivision, setOpenDivision] = useState([])
    const [juniorDivision, setJuniorDivision] = useState([])
    const [participants, setParticipants] = useState([])

    const byTicketNumber = (a, b) => {
      return (a.ticketNumber < b.ticketNumber) ? -1 : (a.ticketNumber > b.ticketNumber) ? 1 : 0;
    }

    useEffect(() => {
      fetchDivisions()
      fetchParticipants()
    }, [])

    function setInput(key, value) {
      setFormState({ ...formState, [key]: value })
    }

    async function fetchDivisions() {
      try {
        const divisionsData = await API.graphql(graphqlOperation(listDivisions))
        const divisions = divisionsData.data.listDivisions.items
        const openDivision = divisions.filter(division => division.name === 'Open').map(division => division.id)[0]
        setOpenDivision(openDivision)
        const juniorDivision = divisions.filter(division => division.name === 'Junior').map(division => division.id)[0]
        setJuniorDivision(juniorDivision)
      } catch (err) { console.log('error fetching divisions') }
    }

   async function fetchParticipants() {
     try {
       const participantData = await API.graphql(graphqlOperation(listParticipants, {
         limit: 400
       }))
       const participants = participantData.data.listParticipants.items
       const sortedParticipants = participants.sort(byTicketNumber)
       const nonDeletedParticipants = sortedParticipants.filter(p => !(p._deleted))
       setParticipants(nonDeletedParticipants)
     } catch (err) { console.log(' error fetching participants', err) }
   }

   async function addParticipant() {
     try {
       const newParticipant = { ...formState }
       const newPhone = newParticipant.phoneNumber ? newParticipant.phoneNumber : '850-555-5555'
       const divId = (newParticipant.division === 'Junior') ? juniorDivision : openDivision
       const newZip = newParticipant.zip ? parseInt(newParticipant.zip) : 0
       var simpleParams = {
         city: newParticipant.city,
         phoneNumber: newPhone,
         division: divId,
         firstName: newParticipant.firstName,
         lastName: newParticipant.lastName,
         state: newParticipant.state,
         streetAddr: newParticipant.streetAddr,
         ticketNumber: newParticipant.ticketNumber,
         zipCode: newZip
         }
        if (newParticipant.email) {
          simpleParams = {
            ...simpleParams,
            email: newParticipant.email
          }
        }
        if (newParticipant.phoneNumber) {
          simpleParams = {
            ...simpleParams,
            phoneNumber: newParticipant.phoneNumber
          }
        }

       await API.graphql(graphqlOperation(simpleParticipant, simpleParams))
     } catch (err) {
       console.log('error creating participant', err)
     }
     setFormState(initialState)
     fetchParticipants()
   }

   async function checkRequiredFields() {
     if (formState.firstName && formState.lastName && formState.ticketNumber) {
       addParticipant()
     }
     else (
       setInput('error', true)
     )
   }

    return (
      <Authenticator hideSignUp={true}>
      {(signOut, user) => (
        <>
        <div style={styles.container}>
        <Heading level={2}>New Participant Entry Form</Heading>
        <Flex direction="row">
        <TextField
        label="First Name"
        size="large"
        padding="20px"
        onChange={event => setInput('firstName', event.target.value)}
        value={formState.firstName}
      />
      <TextField
        label="Last Name"
        size="large"
        padding="20px"
        onChange={event => setInput('lastName', event.target.value)}
        value={formState.lastName}
      />
      </Flex>
      <TextField
        label="Ticket Number"
        size="large"
        padding="20px"
        onChange={event => setInput('ticketNumber', event.target.value)}
        value={formState.ticketNumber}
      />
      <RadioGroupField
        direction="row"
        name="division"
        label="Division:"
        onChange={event => setInput('division', event.target.value)}
        value={formState.division}
        padding="20px"
        size="large"
      >
        <Radio value="Open">Open</Radio>
        <Radio value="Junior">Junior</Radio>
      </RadioGroupField>
      <TextField
        label="Street Address"
        size="large"
        padding="20px"
        onChange={event => setInput('streetAddr', event.target.value)}
        value={formState.streetAddr}
      />
      <Flex direction="row">
      <TextField
        label="City"
        size="large"
        padding="20px"
        onChange={event => setInput('city', event.target.value)}
        value={formState.city}
      />
      <TextField
        label="State"
        size="large"
        padding="20px"
        onChange={event => setInput('state', event.target.value)}
        value={formState.state}
      />
      <TextField
        label="Zip"
        size="large"
        padding="20px"
        onChange={event => setInput('zip', event.target.value)}
        value={formState.zip}
      />
      </Flex>
      <Flex direction="row">
      <TextField
        label="Phone Number"
        size="large"
        padding="20px"
        onChange={event => setInput('phoneNumber', event.target.value)}
        value={formState.phoneNumber}
      />
      <TextField
        label="Email"
        size="large"
        padding="20px"
        onChange={event => setInput('email', event.target.value)}
        value={formState.email}
      />
      </Flex>
      <Button onClick={checkRequiredFields}>Add Participant</Button>
        { (formState.error) &&
            <Alert variation="error" isDismissible={true}>Missing Required Fields</Alert>
        }
        </div>
      <Tabs>
      <TabItem title="Open Division">
      <div style={styles.container}>
        <Heading level={2}>Participants (Open Division)</Heading>
        <Table>
        { participants
          .filter(p => (p.divisionID === openDivision))
          .map(participant =>
            <TableRow key={participant.id}>
              <TableCell>{participant.firstName} {participant.lastName}</TableCell>
              <TableCell>{participant.ticketNumber}</TableCell>
            </TableRow>
          )
        }
        </Table>
        </div>
        </TabItem>
        <TabItem title="Junior Division">
        <div style={styles.container}>
          <Heading level={2}>Participants (Junior Division)</Heading>
          <Table>
          { participants
            .filter(p => (p.divisionID === juniorDivision))
            .map(participant =>
              <TableRow key={participant.id}>
                <TableCell>{participant.firstName} {participant.lastName}</TableCell>
                <TableCell>{participant.ticketNumber}</TableCell>
              </TableRow>
            )
          }
          </Table>
          </div>
          </TabItem>
      </Tabs>
      </>
    )}
    </Authenticator>
    )
  }

  const styles = {
    container: { width: 800, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
    category: {  marginBottom: 15 },
    input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
    categoryName: { fontSize: 20, fontWeight: 'bold' },
    categoryDescription: { marginBottom: 0 },
    button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
  }


export default Participants;
