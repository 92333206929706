import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Participants from "./pages/Participants";
import Results from "./pages/Results";
import Weighin from "./pages/Weighin";

Amplify.configure(awsExports);

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Results />} />
          <Route path="participants" element={<Participants />} />
          <Route path="weighin" element={<Weighin />} />
          <Route path="*" element={<Results />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
