import React from "react";
import { BrowserRouter, Route, Link, useNavigate } from "react-router-dom";
import { Heading, Menu, MenuItem } from '@aws-amplify/ui-react';

function Navbar() {

  let navigate = useNavigate();

  const routeToResults = () => {
    let path = "/";
    navigate(path);
  }

  const routeToParticipants = () => {
    let path = "/participants";
    navigate(path);
  }

  const routeToWeighin = () => {
    let path = "/weighin";
    navigate(path);
  }

  return (
    <>
    <Menu size="large" menuAlign="start">
      <MenuItem onClick={routeToResults}>
        Results
      </MenuItem>
      <MenuItem onClick={routeToParticipants}>
        Participants
      </MenuItem>
      <MenuItem onClick={routeToWeighin}>
        Weigh In
      </MenuItem>
    </Menu>
    </>
  );
}

export default Navbar;
