export const simpleWeighIn = /* GraphQL */ `
  mutation SimpleWeighIn(
    $fishCategory: ID!
    $participant: ID!
    $division: ID!
    $weight: Float!
    $timeStamp: AWSDateTime!
  ) {
    createWeighIn(input: {
      fishcategoryID: $fishCategory,
      participantID: $participant,
      divisionID: $division,
      weight: $weight,
      timestamp: $timeStamp
    }) {
      id
      createdAt
      updatedAt
    }
  }
`;

export const simpleParticipant = /* GraphQL */ `
  mutation SimpleParticipant(
    $city: String
    $phoneNumber: AWSPhone
    $division: ID!
    $email: AWSEmail
    $firstName: String
    $lastName: String
    $state: String
    $streetAddr: String
    $ticketNumber: String!
    $zipCode: Int
  ) {
    createParticipant(input: {
      city: $city,
      contactPhoneNumber: $phoneNumber,
      divisionID: $division,
      email: $email,
      firstName: $firstName,
      lastName: $lastName,
      state: $state,
      streetAddress: $streetAddr,
      ticketNumber: $ticketNumber
      zipCode: $zipCode
    }) {
      id
    }
  }
`;
