import React, {useEffect, useState } from 'react';
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { participantTicketNumber } from '../customgraphql/queries'
import { simpleWeighIn } from '../customgraphql/mutations'
import { listFishCategories } from '../graphql/queries'
import awsExports from "../aws-exports";
import { TextField, Button, SelectField, RadioGroupField, Radio } from '@aws-amplify/ui-react';
import { Heading, Table, TableCell, TableRow, TableBody, Flex, Authenticator } from '@aws-amplify/ui-react';
import TimeField from 'react-simple-timefield';

Amplify.configure(awsExports);



const initialState = { weight: '', participantID: '',
                      ticketNumber: '', time: "12:00",
                      amPm: "PM", date: "2022-05-14" }

const Weighin = () => {

    const [formState, setFormState] = useState(initialState)
    const [participants, setParticipants] = useState([])
    const [categories, setCategories] = useState([])
    const [redFish, setRedFish] = useState([])

    useEffect(() => {
      fetchCategories()
    }, [])

    function setInput(key, value) {
      setFormState({ ...formState, [key]: value })
    }

    const byTicketNumber = (a, b) => {
      return (a.ticketNumber < b.ticketNumber) ? -1 : (a.ticketNumber > b.ticketNumber) ? 1 : 0;
    }

    async function addWeighIn() {
      try {
        const newWeighIn = { ...formState }
        setFormState({ ...initialState, fishCategoryID: categories[0]})
        setParticipants([])

        const splitTime = newWeighIn.time.split(":")
        const minutes = parseInt(splitTime[1])
        const parsedHour = parseInt(splitTime[0])
        const hour = (newWeighIn.amPm === "PM" ? parsedHour + 12 : parsedHour  )

        const splitDate = newWeighIn.date.split("-")
        const year = parseInt(splitDate[0])
        const month = parseInt(splitDate[1]) - 1
        const day = parseInt(splitDate[2])

        const calcTimestamp = new Date(year, month, day, hour, minutes)

        await API.graphql(graphqlOperation(simpleWeighIn, {
          fishCategory: newWeighIn.fishCategoryID,
          participant: participants[0].id,
          division: participants[0].divisionID,
          weight: newWeighIn.weight,
          timeStamp: calcTimestamp }))

      } catch (err) {
        console.log('error creating weigh-in', err)
      }
    }

    async function fetchParticipants(value) {
      if (value) {
        try {
          const participantData = await API.graphql(graphqlOperation(participantTicketNumber, {
            ticketStart: value
          }))
          const foundParticipants = participantData.data.syncParticipants.items
          const sortedParticipants = foundParticipants.sort(byTicketNumber)
          setParticipants(sortedParticipants)
        } catch (err) { console.log(' error fetching participants', err)}
      }
      else {
        setParticipants([])
      }
    }

    async function fetchCategories() {
      try{
        const categoryData = await API.graphql(graphqlOperation(listFishCategories))
        const categories = categoryData.data.listFishCategories.items
        const sortedCategories = categories.sort((a,b) => a.name > b.name ? 1:-1)
        setCategories(sortedCategories)
        const red = categories.filter(c => c.name === "Red Fish (Most Spots)").map(c => c.id)[0]
        setRedFish(red)
        setInput('fishCategoryID', sortedCategories[0].id)
      } catch (err) { console.log(' error fetching categories')}
    }


    return (
      <Authenticator hideSignUp={true}>
      {(signOut, user) => (
        <>
        <div style={styles.container}>
        <Heading level={1} justifyContent="center">Weigh-In Form</Heading>
        <SelectField
          label="Fish Category"
          onChange={event => setInput('fishCategoryID', event.target.value)}
          value={formState.fishCategoryID}
          size="large"
          padding="20px"
        >
        {
          categories.map(fish => (
            <option value={fish.id} key={fish.id}>{fish.name}</option>
          ))
        }
        </SelectField>
        <TextField
          label={(formState.fishCategoryID !== redFish && "Weight") || (formState.fishCategoryID === redFish && "Spot Count")}
          onChange={event => setInput('weight', event.target.value)}
          value={formState.weight}
          outerEndComponent={<Button isDisabled={true}>{(formState.fishCategoryID !== redFish && "lbs") || (formState.fishCategoryID === redFish && "Spots")}</Button>}
          size="large"
          padding="20px"
          />
        <Flex direction="row">
        <TimeField
          value={formState.time}
          onChange={event => setInput('time', event.target.value)}
          input={
            <TextField
              label="Weigh Time"
              padding="20px"
              size="large"
            />}
        />
        <RadioGroupField
          direction="column"
          name="ampm"
          value={formState.amPm}
          padding="20px"
          size="large"
          onChange={event => setInput('amPm', event.target.value)}
        >
        <Radio value="AM">A.M.</Radio>
        <Radio value="PM">P.M.</Radio>
        </RadioGroupField>
        <RadioGroupField
          direction="column"
          name="day"
          value={formState.date}
          padding="20px"
          size="large"
          onChange={event => setInput('date', event.target.value)}
        >
        <Radio value="2022-05-14">Saturday May 14</Radio>
        <Radio value="2022-05-15">Sunday May 15</Radio>
        </RadioGroupField>
        </Flex>
        <TextField
          label="Ticket Number"
          onChange={event => {
            setInput('ticketNumber', event.target.value)
            fetchParticipants(event.target.value)
          }}
          value={formState.ticketNumber}
          size="large"
          padding="20px"
        />
        <Table
          size="large"
          highlightOnHover={true}
          variation="striped"
        >
        <TableBody>
        {
          participants.slice(0,9).map(participant => (
            <TableRow key={participant.id}>
            <TableCell>{participant.ticketNumber}</TableCell>
            <TableCell>{participant.firstName} {participant.lastName}</TableCell>
            </TableRow>
          ))
        }
        </TableBody>
        </Table>
        <Button onClick={addWeighIn}>Submit</Button>
        </div>
        </>
      )}
      </Authenticator>
    );
}

const styles = {
  container: { width: 800, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
  category: {  marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  categoryName: { fontSize: 20, fontWeight: 'bold' },
  categoryDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

export default Weighin;
