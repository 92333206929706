
import React, { useEffect, useState } from 'react'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { listDivisions, listWeighIns, syncParticipants } from '../graphql/queries'
import { listJuniorDivisionFishCategories, listOpenDivisionFishCategories } from '../customgraphql/queries'
import { Heading, Tabs, TabItem, Alert, Link } from '@aws-amplify/ui-react'
import {
  Table,
  TableCell,
  TableRow,
} from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css'

import awsExports from "../aws-exports";
Amplify.configure(awsExports);

const Results = () => {
  const [openCategories, setOpenCategories] = useState([])
  const [juniorCategories, setJuniorCategories] = useState([])
  const [openDivision, setOpenDivision] = useState([])
  const [juniorDivision, setJuniorDivision] = useState([])
  const [weighIns, setWeighIns] = useState([])
  const [participants, setParticipants] = useState([])

  useEffect(() => {
    fetchDivisions()
    fetchCategories()
    fetchWeighIns()
    fetchParticipants()
  }, [])

  async function fetchDivisions() {
    try {
      const divisionsData = await API.graphql(graphqlOperation(listDivisions))
      const divisions = divisionsData.data.listDivisions.items
      const openDivision = divisions.filter(division => division.name === 'Open').map(division => division.id)[0]
      setOpenDivision(openDivision)
      const juniorDivision = divisions.filter(division => division.name === 'Junior').map(division => division.id)[0]
      setJuniorDivision(juniorDivision)
    } catch (err) { console.log('error fetching divisions') }
  }

  async function fetchCategories() {
    try {
      const openCatData = await API.graphql(graphqlOperation(listOpenDivisionFishCategories))
      const juniorCatData = await API.graphql(graphqlOperation(listJuniorDivisionFishCategories))

      const openDiv = openCatData.data.listDivisions.items[0]
      const juniorDiv = juniorCatData.data.listDivisions.items[0]

      setOpenCategories(openDiv.FishCategoriesDiv.items)
      setJuniorCategories(juniorDiv.FishCategoriesDiv.items)
    } catch (err) { console.log(' error fetching categoryDivision relationships', err) }
  }

  async function fetchWeighIns() {
    try {
      const weighInData = await API.graphql(graphqlOperation(listWeighIns, {
        limit: 600
      }))
      const weighIns = weighInData.data.listWeighIns.items
      const nonDeletedWeights = weighIns.filter(p => !(p._deleted))
      setWeighIns(nonDeletedWeights)
    } catch (err) { console.log(' error fetching weighins') }
  }

 async function fetchParticipants() {
   try {
     const participantData = await API.graphql(graphqlOperation(syncParticipants, {
       limit: 400
     }))
     const participants = participantData.data.syncParticipants.items
     setParticipants(participants)
   } catch (err) { console.log(' error fetching participants') }
 }

 const byWeightDesc = (a, b) => {
   if (a.weight === b.weight) {
     return a.timestamp > b.timestamp ? 1:-1
   }
   return b.weight - a.weight;
 }

 const byNameAlphabetical = (a, b) => a.name.localeCompare(b.name)

  return (
    <>
    <Heading level={1} justifyContent="center" margin="0 auto">2022 Gulf Breeze Optimist Fishing Rodeo Results</Heading>
    <Link href="https://www.gulfbreezeoptimistclub.org/fishing-rodeo" isExternal={true}>
      Please visit the Optimist Club of Gulf Breeze website
    </Link>
    <Tabs>
      <TabItem title="Open Division">
      <div style={styles.container}>
        <Heading level={2}>Final Rankings (Open Division)</Heading>
        <Alert
          variation="success"
          isDismissible={false}
          hasIcon={true}
          heading="These are the final results"
          padding="20px"
        >Thank you for participating, see you next year!
        </Alert>
        { openCategories
          .map(c => c.fishCategory)
          .sort(byNameAlphabetical)
          .map(selectedCategory => (
            <div key={selectedCategory.name} style={styles.category}>
              <Heading level={3} style={styles.categoryName}>{selectedCategory.name}</Heading>
              <Table>
              { weighIns
                .filter(w => (w.fishcategoryID === selectedCategory.id))
                .filter(w => (w.divisionID === openDivision))
                .sort(byWeightDesc)
                .slice(0,3)
                .map((selectedWeighIn, index) => (
                  <TableRow key={selectedWeighIn.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{participants.filter(p => (p.id === selectedWeighIn.participantID)).map(p => p.firstName)[0]} {participants.filter(p => (p.id === selectedWeighIn.participantID)).map(p => p.lastName)[0]}</TableCell>
                  <TableCell>{selectedWeighIn.weight} {selectedCategory.name !== "Red Fish (Most Spots)" && "lbs"}</TableCell>

                  </TableRow>
                ))
              }
              </Table>
            </div>
        ))
        }
        </div>
        </TabItem>
        <TabItem title="Junior Division">
        <div style={styles.container}>
          <Heading level={2}>Final Rankings (Junior Division)</Heading>
          <Alert
            variation="success"
            isDismissible={false}
            hasIcon={true}
            heading="These are the final results"
            padding="20px"
          >Thank you for participating, see you next year!
          </Alert>
          { juniorCategories
              .map(c => c.fishCategory)
              .sort(byNameAlphabetical)
              .map(selectedCategory => (
              <div key={selectedCategory.id} style={styles.category}>
                <Heading level={3} style={styles.categoryName}>{selectedCategory.name}</Heading>
                <Table>
                { weighIns
                  .filter(w => (w.fishcategoryID === selectedCategory.id))
                  .filter(w => (w.divisionID === juniorDivision))
                  .sort(byWeightDesc)
                  .slice(0,3)
                  .map((selectedWeighIn, index) => (
                    <TableRow key={selectedWeighIn.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{participants.filter(p => (p.id === selectedWeighIn.participantID)).map(p => p.firstName)[0]} {participants.filter(p => (p.id === selectedWeighIn.participantID)).map(p => p.lastName)[0]}</TableCell>
                    <TableCell>{selectedWeighIn.weight} {selectedCategory.name !== "Red Fish (Most Spots)" && "lbs"}</TableCell>
                    </TableRow>
                  ))
                }
                </Table>
              </div>
          ))
          }
          </div>
        </TabItem>
    </Tabs>
    </>
  )
}

const styles = {
  container: { width: 800, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
  category: {  marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  categoryName: { fontSize: 20, fontWeight: 'bold' },
  categoryDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

export default Results;
