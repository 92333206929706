export const participantTicketNumber = /* GraphQL */ `
  query ParticipantTicketNumber(
    $ticketStart: String!
  ) {
    syncParticipants(
      limit: 300,
      filter: {
        ticketNumber: {
          beginsWith: $ticketStart
        }})
    {
      items {
        id
        ticketNumber
        lastName
        firstName
        divisionID
      }
    }
  }
`;

export const listOpenDivisionFishCategories = /* GraphQL */ `
  query ListOpenDivisionFishCategories {
    listDivisions(filter: {name: {eq: "Open"}}) {
      items {
        FishCategoriesDiv {
          items {
            fishCategory {
              name
              id
            }
          }
        }
      }
    }
  }
`;

export const listJuniorDivisionFishCategories = /* GraphQL */ `
  query ListJuniorDivisionFishCategories {
    listDivisions(filter: {name: {eq: "Junior"}}) {
      items {
        FishCategoriesDiv {
          items {
            fishCategory {
              name
              id
            }
          }
        }
      }
    }
  }
`;
