/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDivision = /* GraphQL */ `
  query GetDivision($id: ID!) {
    getDivision(id: $id) {
      id
      name
      FishCategoriesDiv {
        nextToken
        startedAt
      }
      Participants {
        nextToken
        startedAt
      }
      WeighIns {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDivisions = /* GraphQL */ `
  query ListDivisions(
    $filter: ModelDivisionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDivisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDivisions = /* GraphQL */ `
  query SyncDivisions(
    $filter: ModelDivisionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDivisions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWeighIn = /* GraphQL */ `
  query GetWeighIn($id: ID!) {
    getWeighIn(id: $id) {
      id
      weight
      timestamp
      fishcategoryID
      participantID
      divisionID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listWeighIns = /* GraphQL */ `
  query ListWeighIns(
    $filter: ModelWeighInFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeighIns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        weight
        timestamp
        fishcategoryID
        participantID
        divisionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWeighIns = /* GraphQL */ `
  query SyncWeighIns(
    $filter: ModelWeighInFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWeighIns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        weight
        timestamp
        fishcategoryID
        participantID
        divisionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFishCategory = /* GraphQL */ `
  query GetFishCategory($id: ID!) {
    getFishCategory(id: $id) {
      id
      name
      WeighInCategory {
        nextToken
        startedAt
      }
      divisions {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFishCategories = /* GraphQL */ `
  query ListFishCategories(
    $filter: ModelFishCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFishCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFishCategories = /* GraphQL */ `
  query SyncFishCategories(
    $filter: ModelFishCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFishCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getParticipant = /* GraphQL */ `
  query GetParticipant($id: ID!) {
    getParticipant(id: $id) {
      id
      lastName
      firstName
      ticketNumber
      streetAddress
      city
      state
      zipCode
      contactPhoneNumber
      WeighInPaticipant {
        nextToken
        startedAt
      }
      divisionID
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listParticipants = /* GraphQL */ `
  query ListParticipants(
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParticipants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lastName
        firstName
        ticketNumber
        streetAddress
        city
        state
        zipCode
        contactPhoneNumber
        divisionID
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncParticipants = /* GraphQL */ `
  query SyncParticipants(
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncParticipants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        lastName
        firstName
        ticketNumber
        streetAddress
        city
        state
        zipCode
        contactPhoneNumber
        divisionID
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDivisionFishCategory = /* GraphQL */ `
  query GetDivisionFishCategory($id: ID!) {
    getDivisionFishCategory(id: $id) {
      id
      divisionID
      fishCategoryID
      division {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      fishCategory {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDivisionFishCategories = /* GraphQL */ `
  query ListDivisionFishCategories(
    $filter: ModelDivisionFishCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDivisionFishCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        divisionID
        fishCategoryID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDivisionFishCategories = /* GraphQL */ `
  query SyncDivisionFishCategories(
    $filter: ModelDivisionFishCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDivisionFishCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        divisionID
        fishCategoryID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
